
import { defineComponent } from 'vue'
import {
    getEmployeeSalaryHead
} from '@/api/salary'

export default defineComponent({
    components: {},
    props: {
        cardId: {
            type: [String, Number],
            default: ''
        },
        queryMonth: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            detail: {}
        }
    },
    watch: {
        queryData: {
            handler(newval) {
                console.log(77777, newval)
                if (newval) {
                    this.getData()
                }
            },
            deep: true
        }
    },
    computed: {
        setDefault() {
            return (value: string) => {
                return value ?? '0'
            }
        },
        queryData() {
            return {
                queryMonth: this.queryMonth,
                cardId: this.cardId
            }
        }
    },
    methods: {
        getData() {
            getEmployeeSalaryHead({
                idCardNo: this.cardId as any,
                beginPayTime: this.queryMonth.split(',')[0],
                endPayTime: this.queryMonth.split(',')[1]
            }).then((res: any) => {
                this.detail = res || {}
                this.$emit('basicInfo', this.detail)
            })
        }
    }
})
