
import {defineComponent} from 'vue'
import {ElMessageBox, ElMessage} from 'element-plus'

import tableComponent from '@/components/table-component/index.vue'
import {employeeAllSalary} from "@/api/salary";

export default defineComponent({
  props: {
    cardId: {
      type: [String, Number],
      default: ''
    },
    queryMonth: {
      type: String,
      default: ''
    }
  },
  components: {
    tableComponent
  },
  data() {
    return {
      tableHead: [
        // {
        //   prop: 'index'
        // },
        {
          prop: 'date',
          label: '月份'
        },
        {
          prop: 'totalCount',
          label: '发薪单数',
        },
        {
          prop: 'payRealHairTotalMoney',
          label: '实发金额',
          prefix: '¥'
        },
        {
          prop: 'payStopTotalMoney',
          label: '停发金额',
          prefix: '¥'
        },
        {
          prop: 'payReceiveTotalMoney',
          label: '已领金额',
          prefix: '¥'
        },
        {
          prop: 'payUnclaimedTotalMoney',
          label: '待领金额',
          prefix: '¥'
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      tableData: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
      selects: []
    }
  },
  watch: {
    cardId: {
      handler(val: string) {
        if (val) {
          this.getData()
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSelectionChange(row: any) {
      this.selects = row.map((i: any) => i.idCardNo)
      this.$emit('selections', this.selects)
    },
    handleSizeChange(ev: number) {
      this.pageInfo.pageSize = ev
      this.getData()
    },
    handleCurrentChange(ev: number) {
      this.pageInfo.pageNum = ev
      this.getData()
    },
    getData() {
      const query = {
        idCardNo: this.cardId,
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize
      }
      employeeAllSalary(query).then((res: any) => {
        this.tableData = res.list
        this.pageInfo.total = res.total
        this.$emit('total', res.total)
      })
    }
  }
})
