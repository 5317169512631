import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mg-t-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_staff = _resolveComponent("info-staff")!
  const _component_detail_staff_salary = _resolveComponent("detail-staff-salary")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_info_staff, {
        id: _ctx.id,
        "card-id": _ctx.cardId,
        "query-month": _ctx.queryMonth
      }, null, 8, ["id", "card-id", "query-month"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_detail_staff_salary, {
          id: _ctx.id,
          "card-id": _ctx.cardId,
          "query-month": _ctx.queryMonth,
          "onUpdate:query-month": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryMonth) = $event)),
          month: _ctx.queryMonth,
          onDateChange: _ctx.onDateChange
        }, null, 8, ["id", "card-id", "query-month", "month", "onDateChange"])
      ])
    ]),
    _: 1
  }))
}