import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_billByDetail = _resolveComponent("billByDetail")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_billBySum = _resolveComponent("billBySum")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    shadow: "never",
    class: "container"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
        class: "demo-tabs",
        onTabClick: _ctx.handleClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "按明细",
            name: "detail"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_billByDetail, _mergeProps({
                ref: "billByDetail",
                "card-Id": _ctx.cardId
              }, _ctx.$attrs, {
                onSelections: _ctx.getSelections,
                onDateChange: _ctx.onDateChange
              }), null, 16, ["card-Id", "onSelections", "onDateChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: "按统计",
            name: "sum"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_billBySum, {
                "card-Id": _ctx.cardId,
                onSelections: _ctx.getSelections
              }, null, 8, ["card-Id", "onSelections"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"])
    ]),
    _: 1
  }))
}