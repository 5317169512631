
import { defineComponent } from 'vue'
import billByDetail from './bill-by-detail.vue'
import billBySum from './bill-by-sum.vue'
import { payrollRecordEle, downloadPayrollRecordEle } from '@/api/operation'
import { exportEmployeeSalaryRecord } from '@/api/salary'
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { dateFormat, closewin, downLoadFile } from '@/utils/util'
import router from '@/router'

export default defineComponent({
    components: {
        billByDetail,
        billBySum
    },
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        cardId: {
            type: [String, Number],
            default: ''
        },
        queryMonth: {
            type: String,
            default: ''
        },
        basicInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            activeName: 'detail',
            selects: []
        }
    },
    beforeMount() {
        // 被跳转到该页面恢复原页面数据
        console.log(212121, router)
        if (/tip/.test(router.options.history.base)) {
            ElMessageBox.alert('回单正在下载中，您可以在该界面继续操作')
            const channel = new BroadcastChannel('new_tab_staffBillDetail')
            let isLoad = 0
            channel.onmessage = e => {
                const data = JSON.parse(e.data)
                isLoad = 1
                channel.postMessage('staffBillDetail_close')
                channel.close()
                this.activeName = data.activeName
                if (this.activeName === 'detail') {
                    (this.$refs as any).billByDetail.searchList = data.searchList
                        ; (this.$refs as any).billByDetail.getData()
                }
            }
            setTimeout(() => {
                if (!isLoad) {
                    window.location.search = ''
                }
                channel.close()
            }, 5000)
        }
    },

    methods: {
        handleClick(ev: Event) {
            console.log(ev)
        },
        getSelections(selection: any) {
            this.selects = selection
        },
        onDateChange(ev: string) {
            console.log(123, ev)
            this.$emit('dateChange', ev)
        }
    }
})
