
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
import { getEmployeeSalaryRecord } from '@/api/salary'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import { payrollRecordEle, downloadPayrollRecordEle, downloadCreatePersonnelReceipt } from '@/api/operation'
import { dateFormat, closewin, downLoadFile } from '@/utils/util'
import { payrollRecordExport } from '@/api/export'
import store from '@/store/index'

export default defineComponent({
    components: {
        searchComponent,
        tableComponent
    },
    props: {
        cardId: {
            type: [String, Number],
            default: ''
        }
        // queryMonth: {
        //   type: String,
        //   default: ''
        // }
    },
    data() {
        return {
            // 操作状态(0 未领取 1 已领取 2 停止发薪 3进行中 4领取失败)
            statusMap: {
                0: '未领取',
                1: '已领取',
                2: '停止发薪',
                3: '进行中',
                4: '领取失败'
            },
            searchList: {
                status: {
                    name: '领薪状态',
                    prop: 'status',
                    type: 'select',
                    placeholder: '请选择',
                    multiple: false,
                    value: '',
                    defaultValue: '',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '未领取',
                            value: 0
                        },
                        {
                            label: '已领取',
                            value: 1
                        },
                        {
                            label: '停止发薪',
                            value: 2
                        }
                    ],
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                },
                payTime: {
                    name: '发薪时间',
                    prop: 'PayTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [],
                    handleChange: (item: any) => {
                        if (item.value?.length) {
                            this.$emit('update:queryMonth', item.value.join(','))
                            // this.$emit('dateChanged', item.value.join(','))
                            //   const startTime = new Date(item.value[0]).getTime()
                            //   const endTime = new Date(item.value[1]).getTime()
                            //   const startMonth = dayjs(startTime).month()
                            //   const endMonth = dayjs(endTime).month()
                            //   const limitDay = (startMonth + 1 === 7 && endMonth + 1 === 8) ? 61 : 60
                            //   if (endTime - startTime > limitDay * 24 * 60 * 60 * 1000) {
                            //     ElMessage({
                            //       message: '最多只能选择61天',
                            //       type: 'warning'
                            //     })
                            //     item.value = []
                            //     return
                            //   }
                        }
                    }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            buttonList: [
                {
                    name: '下载子弹回单',
                    icon: 'export',
                    plain: true,
                    fn: () => {
                        this.payrollBulletReceipt()
                    }
                },
                {
                    name: '生成微信/支付宝回单',
                    icon: 'export',
                    plain: true,
                    fn: () => {
                        this.applyReceipt()
                    }
                },
                {
                    name: '下载微信/支付宝回单',
                    icon: 'export',
                    type: 'success',
                    plain: true,
                    fn: () => {
                        this.downloadReceiptOld()
                    }
                },
                {
                    name: '导出明细',
                    buttonType: 'export',
                    fn: () => {
                        this.onExport()
                    }
                }
            ] as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'projectName',
                    label: '所属项目',
                    width: 170
                },
                {
                    prop: 'serveCompanyName',
                    label: '所属企业',
                    width: 170
                },
                {
                    prop: 'userName',
                    label: '申请人'
                },
                {
                    prop: 'payTime',
                    label: '发薪时间',
                    width: 160
                },
                {
                    prop: 'paymentAmount',
                    label: '发薪金额',
                    prefix: '¥'
                },
                {
                    prop: 'payAccountFlag',
                    label: '支付方式',
                    width: 160
                },
                {
                    prop: 'status',
                    label: '领薪状态'
                },
                {
                    prop: 'getSalaryTime',
                    label: '领薪时间',
                    width: 160,
                    defaultValue: '--'
                },
                {
                    prop: 'billStatusMsg',
                    label: '回单状态'
                    // defaultValue: ''
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            selects: [],
            timer: 0
        }
    },
    watch: {
        // queryMonth: {
        //   handler(newval) {
        //     if (newval) {
        //       this.searchList.payTime.value = this.queryMonth ? this.queryMonth.split(',') : []
        //       this.getData()
        //     }
        //   },
        //   immediate: true
        // },
        // cardId: {
        //   handler(newval) {
        //     if (newval) {
        //       this.getData()
        //     }
        //   },
        //   immediate: true
        // }
        listenData: {
            handler(newval) {
                if (newval) {
                    console.log('jdfhndfhu', newval)
                    this.searchList.payTime.value = newval.queryMonth ? newval.queryMonth.split(',') : []
                    this.getData()
                }
            }
            // immediate: true,
            //   deep: true
        }
    },
    computed: {
        listenData() {
            return {
                queryMonth: this.$attrs.month,
                cardId: this.cardId
            }
        }
    },
    // mounted() {
    //   if
    //   //       this.searchList.payTime.value = newval.queryMonth ? newval.queryMonth.split(',') : []

    // },

    methods: {
        async payrollBulletReceipt() {
            console.log('gdisuyguhiydghiuydsghiudhiuygiudghiuydiughiuhyd')
            const query = this.createQuery(false)
            query.idCardNo = this.cardId
            let res: any = await downloadCreatePersonnelReceipt(query)
            console.log(res, 'disjgiud')
            const a = document.createElement('a')
            a.href = res.msg || res.fileUrl
            a.download = res.msg || res.fileUrl
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            // window.open(res.msg || res.fileUrl)
        },
        handleSelectionChange(row: any) {
            this.selects = row.map((item: any) => item.id)
            this.$emit('selections', this.selects)
        },
        createQuery(hasPage = true) {
            const query = {} as any
            if (hasPage) {
                query.pageNum = this.pageInfo.pageNum
                query.pageSize = this.pageInfo.pageSize
            }
            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`begin${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else if (this.searchList[key].type === 'select') {
                    if (this.searchList[key].multiple) {
                        query[(this.searchList[key]).prop] = this.searchList[key].value.join(',')
                    } else {
                        query[(this.searchList[key]).prop] = this.searchList[key].value
                    }
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            console.log('t1')
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleSizeChange(ev: number) {
            console.log('t2')
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            console.log('t3')
            this.pageInfo.pageNum = ev
            this.getData()
        },
        async getData() {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(async () => {
                const query = this.createQuery()
                query.idCardNo = this.cardId
                const res = await getEmployeeSalaryRecord(query) as any
                this.tableData = res.list || []
                this.pageInfo.total = res.total || 0
            }, 300)
        },
        // 一键申请回单
        applyReceipt() {
            payrollRecordEle({
                ids: this.selects.join(','),
                beginPayTime: '',
                endPayTime: '',
                beginGetSalaryTime: '',
                endGetSalaryTime: '',
                ...this.createQuery(false)
            }).then(() => {
                ElMessage.success('申请成功')
            })
        },
        // 下载申请回单
        async downloadReceiptOld() {
            if (!this.selects.length) {
                this.$message.error('请至少选择一条数据')
                return
            }
            const result = await downloadPayrollRecordEle({
                ids: this.selects.join(','),
                ...this.createQuery(false)
            })
            this.windowOpen()
            document.title = '正在下载中...'
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '回单正在下载中，您可以在新界面继续操作'
            })
            const files = await axios({
                url:
                    'https://1403244436326619.cn-shanghai.fc.aliyuncs.com/2016-08-15/proxy/zip-service/zip-oss/',
                method: 'post',
                data: result
            })
            window.location.href = files.data
            await this.$message.success('导出成功！')
            await loading.close()
            closewin()
        },
        windowOpen() {
            window.open(
                window.location.pathname +
                '?tip' + location.hash
            )
            const channel = new BroadcastChannel('new_tab_staffBillDetail')
            const timer = setInterval(() => {
                let data = {
                    activeName: 'detail',
                    searchList: this.searchList
                } as any
                // if (this.activeName === 'detail') {
                //   data.searchList = (this.$refs as any).billByDetail.searchList
                // }
                channel.postMessage(JSON.stringify(data))
                console.log('postMessage')
            }, 50)
            channel.onmessage = e => {
                if (e.data === 'staffBillDetail_close') {
                    clearInterval(timer)
                    channel.close()
                }
            }
        },
        // 导出明细
        async onExport() {
            const result = await payrollRecordExport({
                ids: this.selects.join(','),
                ...this.createQuery(false),
                idCardNo: this.cardId
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        }
        // handleExport() {
        //   if (!this.selects?.length) {
        //     this.$message.warning('请至少选择一条数据')
        //     return
        //   }
        //   const query = {
        //     queryMonth: (this as any).queryMonth,
        //     idCardNos: (this as any).selects.join(','),
        //     // status: (this as any).statusMap[this.activeName]
        //   }
        //   exportEmployeeSalaryRecord(query).then((res: any) => {
        //     window.location.href =
        //       process.env.VUE_APP_BASE_URL +
        //       '/common/download?delete=true&fileName=' +
        //       res
        //     this.$message.success('导出成功！')
        //   })
        // }
        // async onExport () {
        //   const result = await exportBillDetail({
        //     serveCompanyId: this.serveCompanyId as string,
        //     beginPayTime: this.startTime,
        //     endPayTime: this.endTime,
        //     ...this.createQuery(false),
        //     queryStatus: this.searchList.status.value
        //   })
        //   window.location.href = process.env.VUE_APP_BASE_URL + '/common/download?delete=true&fileName=' + result

        //   this.$message.success('导出成功！')
        // }
    }
})
